import { MutationType } from "@/store/mutations/type";
import { Project } from "@/interfaces/Project";
import { StoreType } from "@/interfaces/StoreType";

export default {
  [MutationType.SET_PROJECTS]: (
    state: StoreType,
    data: Array<Project>
  ): void => {
    state.projects = data;
  },
  [MutationType.SET_PROJECT]: (state: StoreType, data: Project): void => {
    state.project = data;
  },
};
