import { GettersType } from "@/store/getters/type";
import { UserType } from "@/interfaces/UserType";
import MainError from "@/exception/MainError";
import { UserRole } from "@/interfaces/UserRole";
import { StoreType } from "@/interfaces/StoreType";

export default {
  [GettersType.GET_CURRENT_USER]: (state: StoreType): UserType | null =>
    state.user,
  [GettersType.IS_ADMIN]: (): boolean => {
    try {
      const data: string | null = localStorage.getItem("etsaUser");
      if (!data) return false;
      const user: UserType = JSON.parse(data);
      return user.roles?.includes(UserRole.ADMIN);
    } catch (e) {
      throw new MainError({ message: "Ususaio sin autorizo" });
    }
  },
};
