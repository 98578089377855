export enum MutationType {
  SET_USER = "setUser",
  SET_TOKEN = "setToken",
  SET_STATUS_TICKETS = "setStatusTickets",
  SET_PROJECTS = "setProjects",
  SET_PROJECT = "setProject",
  SET_TICKET_TYPES = "setTicketType",
  SET_DEPARTMENTS = "setDepartments",
  SET_TICKETS = "setTickets",
  SET_PRIORITIES = "setPriorities",
}
