import { Departamento } from "@/interfaces/Departamento";
import Api from "@/services/api";
import { AxiosResponse } from "axios";
import MAxiosError from "@/exception/MAxiosError";

export default class DepartamentService {
  static async listDep(client: string): Promise<Array<Departamento>> {
    try {
      const response: AxiosResponse = await Api().get(
        `/api/clients/${client}/departments`
      );
      const result: Array<Departamento> = response.data["hydra:member"];
      return result;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
}
