import MainError from "@/exception/MainError";
import { AxiosError } from "axios";

export default class MAxiosError extends MainError {
  constructor(value: unknown) {
    if (value instanceof AxiosError && value.response && value.response.data) {
      super({
        code: value.response.data.code,
        message: value.response.data?.message,
      });
    }
    if (value instanceof TypeError) {
      super({ message: value.message });
    }
    if (value && typeof value === "string") super({ message: value });
  }
}
