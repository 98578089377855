import { ActionType } from "@/store/actions/type";
import { Departamento } from "@/interfaces/Departamento";
import DepartamentService from "@/services/dep.service";
import { MutationType } from "@/store/mutations/type";
import { Commit } from "vuex";

export default {
  [ActionType.LIST_DEPARTMENT]: async (
    { commit }: { commit: Commit },
    client: string
  ): Promise<Array<Departamento> | boolean> => {
    try {
      const result: Array<Departamento> = await DepartamentService.listDep(
        client
      );
      commit(MutationType.SET_DEPARTMENTS, result);
      return result;
    } catch (e) {
      return false;
    }
  },
};
