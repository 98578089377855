import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { UserType } from "@/interfaces/UserType";
import { MetaType } from "@/interfaces/MetaType";
import { Project } from "@/interfaces/Project";
import { ActionType } from "@/store/actions/type";
import store from "@/store";
import { GettersType } from "@/store/getters/type";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/ForgetPasswordView.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/ChangePassword.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER"] },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN"] },
  },
  {
    path: "/tickets/project/:id",
    name: "tickets",
    component: () => import("@/views/Tickets/ListTicketsView.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER"] },
  },
  {
    path: "/new-ticket/project/:id",
    name: "new-ticket",
    component: () => import("@/views/Tickets/NewTicketView.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER"] },
  },
  {
    path: "/ticket/project/:id/status/:name",
    name: "status-tickets",
    component: () => import("@/views/Tickets/ListTicketsView.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN"] },
  },
  {
    path: "/edit-ticket/:idTicket/project/:id",
    name: "edit-ticket",
    component: () => import("@/views/Tickets/EditTicketView.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER"] },
  },
  {
    path: "/tickets/:status/project/:id",
    name: "tickets-filter",
    component: () => import("@/views/Tickets/ListTicketsView.vue"),
    meta: { authorize: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER"] },
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/notHavePermission",
    name: "NotHavePermission",
    components: {
      default: () => import("@/views/NotHaveAccesView.vue"),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const canUserAccess = async (to: RouteLocationNormalized): Promise<boolean> => {
  //   console.log("TO",to);
  const user: UserType = store.getters[GettersType.GET_CURRENT_USER];
  if (!user) return false;
  if (!to || !to.meta) return false;
  const meta: MetaType = to.meta;
  if (!meta.authorize || meta.authorize.length === 0) return false;
  return meta.authorize.some((v: string) => user.roles?.includes(v));
};
const isAdmin = (user: UserType): boolean => {
  if (!user) return false;
  if (!user.roles) return false;
  return user.roles.includes("ROLE_CUSTOMER_ADMIN");
};
const isUser = (user: UserType): boolean => {
  if (!user) return false;
  if (!user.roles) return false;
  return user.roles.includes("ROLE_CUSTOMER");
};
const goToHomeClient = async (next: NavigationGuardNext): Promise<void> => {
  const user: UserType = store.getters[GettersType.GET_CURRENT_USER];
  if (!user || !user.customer || !user.customer.id) return;
  const projects: Array<Project> = await store.dispatch(
    ActionType.SEARCH_PROJECTS,
    {
      client: user.customer.id,
    }
  );
  const project: Project = projects[0];
  return next({ name: "tickets", params: { id: project.id } });
};
const validLoginUser = async (): Promise<boolean> => {
  const user: UserType = store.getters[GettersType.GET_CURRENT_USER];
  if (!user || !user.id) {
    const result: boolean = await store.dispatch(ActionType.REFRESH_LOGIN);
    return result;
  }
  return true;
};
// This callback runs before every route change, including on page load.
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    if (to.name === "login" || to.name === "forget-password") next();
    const valid: boolean = await validLoginUser();
    console.log("Valid Login", valid);
    if (!valid) {
      return next({ name: "login" });
    }
    const user: UserType = store.getters[GettersType.GET_CURRENT_USER];
    console.log("AQQQQQ", user);

    if (!user && to.name !== "login") {
      console.log("no aute");
      return next({ name: "login" });
    }
    if (to.name === "NotHavePermission") return next();

    if (to.path === "/" && user) {
      if (isAdmin(user)) return next({ name: "home" });
      if (isUser(user)) return await goToHomeClient(next);
    }

    const canAccess: boolean = await canUserAccess(to);
    if (!canAccess) {
      return next({ name: "NotHavePermission" });
    }
    return next();
  }
);

export default router;
