import { AxiosResponse } from "axios";
import Api from "@/services/api";
import { Project } from "@/interfaces/Project";
import MAxiosError from "@/exception/MAxiosError";

export default class ProjectService {
  static async search(
    dateIni: string | null,
    dateFin: string | null,
    name: string | null,
    client: string
  ): Promise<Array<Project>> {
    try {
      const urlDateIni: string = !dateIni
        ? ""
        : `&initialDate[before]=${dateIni}`;
      const urlDateFin: string = !dateFin
        ? ""
        : `&initialDate[after]=${dateFin}`;
      const urlProject: string = !name ? "" : `&name=${name}`;
      const result: AxiosResponse = await Api().get(
        `/api/clients/${client}/projects?archived=false${urlDateIni}${urlDateFin}${urlProject}`
      );
      const projects: Array<Project> = result.data["hydra:member"];
      return projects;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async getData(id: string): Promise<Project> {
    try {
      const result: AxiosResponse = await Api().get(`/api/projects/${id}`);
      const project: Project = result.data;
      return project;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }

  static async getTimes(
    id: string,
    from: string | null,
    to: string | null,
    tags: Array<string> | null
  ): Promise<Project> {
    try {
      const urlTags: string =
        !tags || tags.length === 0 ? "" : `&tags[]=${tags}`;
      const result: AxiosResponse = await Api().get(
        `/api/projects/${id}/billable_time?from=${from}&to=${to}${urlTags}`
      );
      return result.data;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
}
