import User from "@/services/user";
import { ActionType } from "@/store/actions/type";
import { UserType } from "@/interfaces/UserType";
import { MutationType } from "@/store/mutations/type";
import { AxiosResponse } from "axios";
import { Commit } from "vuex";
import MAxiosError from "@/exception/MAxiosError";
import { FormChangePassword } from "@/interfaces/FormChangePassword";
export default {
  /**
   * Login to app
   */
  [ActionType.LOGIN]: async (
    { commit }: { commit: Commit },
    params: UserType
  ): Promise<UserType | Error> => {
    try {
      const response: AxiosResponse = await User.login(
        params.email,
        params.password,
        params.site
      );
      const user: UserType = response.data.extranetUser;
      localStorage.setItem("etsaUser", JSON.stringify(user));
      localStorage.setItem("tokenAuths", response.data.token);
      commit(MutationType.SET_USER, user);
      commit(MutationType.SET_TOKEN, response.data.token);

      return user;
    } catch (e) {
      throw new MAxiosError(e);
    }
  },
  [ActionType.REFRESH_LOGIN]: async ({
    commit,
  }: {
    commit: Commit;
  }): Promise<boolean> => {
    const dataUser: string | null = localStorage.getItem("etsaUser");
    if (!dataUser) return false;
    const user: UserType = JSON.parse(dataUser);
    commit(MutationType.SET_USER, user);
    return true;
  },
  /**
   * Loggout of app
   */
  [ActionType.LOGOUT]: ({ commit }: { commit: Commit }): boolean => {
    commit(MutationType.SET_USER, "");
    commit(MutationType.SET_TOKEN, "");
    localStorage.removeItem("tokenAuths");
    localStorage.removeItem("etsaUser");
    return true;
  },
  [ActionType.FIND_USERS]: async (
    { commit }: { commit: Commit },
    client: string
  ): Promise<Array<UserType> | boolean> => {
    try {
      const response: AxiosResponse = await User.getUser(client);
      const users: Array<UserType> = response.data["hydra:member"];
      return users;
    } catch (e) {
      return false;
    }
  },
  [ActionType.FORGET_PASSWORD]: async (
    { commit }: { commit: Commit },
    email: string
  ): Promise<boolean> => {
    try {
      await User.forgetPassword(email);
      return true;
    } catch (e) {
      return false;
    }
  },
  [ActionType.CHANGE_PASSWORD]: async (
    { commit }: { commit: Commit },
    data: FormChangePassword
  ): Promise<boolean> => {
    try {
      await User.changePassword(data);
      return true;
    } catch (e) {
      return false;
    }
  },
};
