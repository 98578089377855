import Api from "./api";
import axios, { AxiosResponse } from "axios";
import MAxiosError from "@/exception/MAxiosError";
import { FormChangePassword } from "@/interfaces/FormChangePassword";
export default class User {
  static async login(
    email: string,
    password: string,
    site: string
  ): Promise<AxiosResponse> {
    return Api().post("login", {
      email,
      password,
      site,
    });
  }
  static async getUser(client: string): Promise<AxiosResponse> {
    try {
      return Api().get(`/api/clients/${client}/extranet_users`);
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async forgetPassword(email: string): Promise<AxiosResponse> {
    try {
      const api = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: false,
        headers: {
          Accept: "application/ld+json",
          "Content-Type": "application/ld+json",
        },
      });
      return api.post(`/api/extranet/send_password_recovery?email=${email}`, {
        params: { email },
      });
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async changePassword(
    user: FormChangePassword
  ): Promise<AxiosResponse> {
    try {
      return Api().post(`/api/extranet/change_password`, user);
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
}
