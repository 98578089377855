import { MutationType } from "@/store/mutations/type";
import { Departamento } from "@/interfaces/Departamento";
import { StoreType } from "@/interfaces/StoreType";

export default {
  [MutationType.SET_DEPARTMENTS]: (
    state: StoreType,
    data: Array<Departamento>
  ) => (state.dep = data),
};
