import { MutationType } from "@/store/mutations/type";
import { StatusTickets } from "@/interfaces/StatusTickets";
import { TicketsType } from "@/interfaces/TicketsType";
import { Ticket } from "@/interfaces/Ticket";
import { Priority } from "@/interfaces/priority";
import { StoreType } from "@/interfaces/StoreType";

export default {
  [MutationType.SET_STATUS_TICKETS]: (
    state: StoreType,
    data: StatusTickets
  ): void => {
    state.statusTickets = data;
  },
  [MutationType.SET_TICKET_TYPES]: (
    state: StoreType,
    data: Array<TicketsType>
  ): void => {
    state.ticketType = data;
  },
  [MutationType.SET_TICKETS]: (state: StoreType, data: Array<Ticket>): void => {
    state.tickets = data;
  },
  [MutationType.SET_PRIORITIES]: (
    state: StoreType,
    data: Array<Priority>
  ): void => {
    state.priorities = data;
  },
};
