import { MutationType } from "@/store/mutations/type";
import { UserType } from "@/interfaces/UserType";
import { StoreType } from "@/interfaces/StoreType";

export default {
  [MutationType.SET_USER]: (state: StoreType, data: UserType): void => {
    state.user = data;
  },
  [MutationType.SET_TOKEN]: (state: StoreType, token: string): void => {
    state.token = token;
  },
};
