export enum GettersType {
  GET_STATUS_TICKETS = "getStatusTickets",
  GET_PROJECTS = "getProjects",
  GET_PROJECT = "getProject",
  GET_TICKET_TYPES = "getTicketTypes",
  GET_DEPARTAMENTOS = "getDepartamentos",
  GET_TICKETS = "getTickets",
  GET_PRIORITIES = "getPriorities",
  GET_CURRENT_USER = "getCurrentUser",
  IS_ADMIN = "isAdmin",
}
