import { GettersType } from "@/store/getters/type";
import { StatusTickets } from "@/interfaces/StatusTickets";
import { TicketsType } from "@/interfaces/TicketsType";
import { Ticket } from "@/interfaces/Ticket";
import { Priority } from "@/interfaces/priority";
import { StoreType } from "@/interfaces/StoreType";

export default {
  [GettersType.GET_STATUS_TICKETS]: (state: StoreType): StatusTickets =>
    state.statusTickets,
  [GettersType.GET_TICKET_TYPES]: (state: StoreType): Array<TicketsType> =>
    state.ticketType,
  [GettersType.GET_TICKETS]: (state: StoreType): Array<Ticket> => state.tickets,
  [GettersType.GET_PRIORITIES]: (state: StoreType): Array<Priority> =>
    state.priorities,
};
