import { ActionType } from "@/store/actions/type";
import { SearchParams } from "@/interfaces/SearchParams";
import ProjectService from "@/services/project.service";
import { MutationType } from "@/store/mutations/type";
import { Project } from "@/interfaces/Project";
import { Commit } from "vuex";

export default {
  [ActionType.SEARCH_PROJECTS]: async (
    { commit }: { commit: Commit },
    params: SearchParams
  ): Promise<Array<Project> | boolean> => {
    try {
      const search: Array<Project> = await ProjectService.search(
        params.dateIni,
        params.dateFin,
        params.name,
        params.client
      );
      commit(MutationType.SET_PROJECTS, search);
      return search;
    } catch (e) {
      return false;
    }
  },
  [ActionType.FIND_DATA_ONE_PROJECT]: async (
    { commit }: { commit: Commit },
    id: string
  ): Promise<Project | boolean> => {
    try {
      const project: Project = await ProjectService.getData(id);
      commit(MutationType.SET_PROJECT, project);
      return project;
    } catch (e) {
      return false;
    }
  },
};
